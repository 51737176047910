import request from "@/request/request";

export default {
  // 登录接口
  login(userData) {
    return request({
      url: "rest/user/login",
      methond: "post",
      data: {
        ...userData
      }
    });
  },
  // 获取员工列表
  queryUser(data) {
    return request({
      url: "rest/user/query",
      methond: "post",
      data: {
        ...data
      }
    });
  },
  // 新增员工
  addUser(data) {
    return request({
      url: "/rest/user/save",
      methond: "post",
      data: {
        ...data
      }
    });
  },
  // 停用员工
  disableUser(id) {
    return request({
      url: "rest/user/disable/" + id,
      methond: "post"
    });
  },
  // 启用员工
  enableUser(id) {
    return request({
      url: "rest/user/enable/" + id,
      methond: "post"
    });
  },
  // 获取订单列表
  queryOrder(params) {
    return request({
      url: "rest/payorder/queryOrder",
      methond: "post",
      data: {
        ...params
      }
    });
  },
  // 导出订单信息
  exportOrder(params) {
    return request({
      url: "rest/payorder/exportOrder",
      methond: "post",
      data: {
        ...params
      },
      responseType: "blob"
    });
  },
  // 获取员工信息
  getUser(uuid) {
    return request({
      url: `rest/user/get/${uuid}`,
      methond: "get"
    });
  },
  // 获取角色列表
  getRoleAll() {
    return request({
      url: `rest/role/all`,
      methond: "get"
    });
  },
  // 新建角色
  saveRole(data, flag) {
    if (flag) {
      let params = {
        "createInfo": {
          "enterprise": null,
          "operator": {
            "id": data.creatorId,
            "operName": data.creator
          },
          "time": data.created
        },
        "lastModifyInfo": {
          "enterprise": null,
          "operator": {
            "id": data.lastModifierId,
            "operName": data.lastModifier
          },
          "time": data.lastModified
        },
        "version": data.version,
        "name": data.name,
        "uuid": data.uuid
      };
      return request({
        url: `rest/role/update`,
        methond: "post",
        data: { updated: [{ ...params }] }
      });
    } else {
      return request({
        url: `rest/role/save`,
        methond: "post",
        data: {
          ...data
        }
      });
    }
  },
  // 获取到所有的权限
  getAllActions() {
    return request({
      url: `rest/role/getAllActions`,
      methond: "get"
    });
  },
  // 获取到角色所有的权限
  getRolePermissions(roleUuid) {
    return request({
      url: `rest/role/getRolePermissions/${roleUuid}`,
      methond: "get"
    });
  },
  // 保存新的权限
  saveAuthorization(data) {
    return request({
      url: `rest/role/saveAuthorization`,
      methond: "post",
      data: {
        ...data
      }
    });
  },
  // 获取油站代码
  getAllStoresByOrgUuid(companyUuid) {
    return request({
      url: `rest/mdata/store/getAllStoresByOrgUuid/${companyUuid}`,
      methond: "get"
    });
  },
  // 获取组织列表
  organizationQuery(params) {
    return request({
      url: `rest/organization/query`,
      methond: "post",
      data: {
        ...params
      }
    });
  },
  // 启用组织
  organizationEnable(id) {
    return request({
      url: `rest/organization/enable?uuid=${id}`,
      methond: "post"
    });
  },
  // 禁用组织
  organizationDisable(id) {
    return request({
      url: `rest/organization/disable?uuid=${id}`,
      methond: "post"
    });
  },
  // 获取组织详情
  organizationGet(id) {
    return request({
      url: `rest/organization/get?uuid=${id}`,
      methond: "get"
    });
  },
  /**
   * 所属组织
   */
  getOrgTree() {
    return request({
      url: `rest/organization/getOrgTree`,
      methond: "get"
    });
  },
  /**
   * 获取附件
   */
  attachmentGet(params) {
    return request({
      url: `rest/attachment/get.thor`,
      methond: "get",
      data: {
        ...params
      }
    });
  },
  /*
   *组织形态
   */
  getStoreTypes(allowEmpty) {
    var params = {
      allowEmpty: allowEmpty
    };
    return request({
      url: `rest/organization/getStoreTypes`,
      methond: "post",
      data: {
        ...params
      }
    });
  },
  /*
  *新建
  */
  addUserObj(code, name, shortName, phone, address, isLeaf, contact, upper) {
    var formData = {
      code,
      name,
      shortName,
      phone,
      address,
      isLeaf,
      contact,
      upper
    };
    return request({
      url: `rest/organization/save`,
      methond: "post",
      data: {
        ...formData
      }
    });
  },
  /*
  *编辑
  */
  editOrg(uuid, version, createInfo, lastModifyInfo, enterprise, attachmentId, enabled, level, path, namePath, authentication, storeType, orgType, code, name, shortName, phone, address, isLeaf, contact, upper) {
      var formData = {
        uuid,
        version,
        createInfo,
        lastModifyInfo,
        enterprise,
        attachmentId,
        enabled,
        level,
        path,
        namePath,
        authentication,
        storeType,
        orgType,
        code,
        name,
        shortName,
        phone,
        address,
        isLeaf,
        contact,
        upper
      };
      return request({
        url: `rest/organization/save`,
        methond: "post",
        data: {
          ...formData
        }
      });

  },
  /*
  *   角色
  */
  getAll(params) {
    return request({
      url: `rest/role/getAll`,
      methond: "get",
      data: {
        ...params
      }
    });
  },
  /*
  *   职位
  */
  getAllPosition(params) {
    return request({
      url: `rest/user/getAllPosition`,
      methond: "get",
      data: {
        ...params
      }
    });
  },
}