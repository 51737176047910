<template>
  <div class="inputBox">
    <img src="../../assets/8355882c56fb2af394e6f247b4b8b1e.png" alt="" class="logo">
    <h1 class="welcome">道达尔智慧油站管理平台</h1>
    <!-- <el-input
      class="account"
      v-model="account"
      placeholder="请输入用户名"
    ></el-input> -->
    <el-form>
      <el-form-item prop="account">
        <span class="el-icon-user-solid icon_box">
          <!-- <svg-icon icon-class="user" /> -->
        </span>
        <el-input name="account" type="text" size="big" v-model="account" placeholder="请输入用户名" :maxlength="20" />
      </el-form-item>
      <el-form-item prop="password">
        <span class="icon_box">
          <img src="../../assets/mima.png" alt="">
        </span>
        <el-input
          v-if="isShowpwd"
          v-model="pwd"
          type="text"
          placeholder="请输入密码"
        >
          <i
            slot="suffix"
            class="iconfont icon-yanjing1"
            @click="isShowpwd = false"
          ></i>
        </el-input>
        <el-input v-else v-model="pwd" type="password" placeholder="请输入密码">
          <i
            slot="suffix"
            class="iconfont icon-yanjing"
            @click="isShowpwd = true"
          ></i>
        </el-input>
      </el-form-item>
    </el-form>
    <div>
      <el-checkbox v-model="rtpwd">记住我</el-checkbox>
    </div>
    <el-button class="loginBtn" @click="loginBtnClick">登 录</el-button>
  </div>
</template>

<style lang="less" scoped>
.welcome {
  margin-bottom: 20px;
  color: #000000;
  font-size: 28px;
  text-align: center;
}


.inputBox {
  position: relative;
  width: 524px;
  height: 506px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-position: 0 0;
  padding: 10px 50px 40px;
  box-sizing: border-box;
  top: 50%;
  margin-top: -228px;
  background-color: #fff;
}
@deep: ~">>>";

.el-form-item {
  border: 1px solid #000;
  // background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  color: #454545;
}
/deep/ .el-form-item__content {
  display: flex;
  align-items: center;
  .el-icon-user-solid {
    font-size: 27px;
  }
  .icon_box {
    width: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    color: #000;
    background-color: #F0F0F0;
    height: 47px !important;
    img {
      width: 23px;
      margin-left: 2px;
    }
  }
}
/deep/ .el-input__inner {
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  height: 47px;
}
.operate {
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  // font-size: 14px;
  div {
    cursor: pointer;
    vertical-align: middle;
    a {
      height: 100%;
      vertical-align: middle;
      color: #eee;
    }
  }
  div:nth-child(3) a {
    color: #f8b849;
  }

  @{deep}.el-checkbox {
    .el-checkbox__label {
      font-size: 12px;
      padding-left: 8px;
      color: #eee;
      // vertical-align: middle;
    }
    .el-checkbox__inner {
      // vertical-align: middle;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      // &::after {
      // left: 5px;
      // height: 8px;
      // width: 4px;
      // }
    }
  }
}
</style>

<script>
import Api from "@/api/index";
export default {
  data() {
    return {
      // account: "admin",
      // pwd: "thoradmin",
      account: "",
      pwd: "",
      isShowpwd: false,
      rtpwd: false
    };
  },
  methods: {
    loginBtnClick() {
      Api.login({
        password: this.pwd,
        login: this.account,
        authenticode: "211534962"
      })
        .then(res => {
          console.log(res);
          if (res.data.code == "0") {
            if (this.rtpwd) {
              localStorage.setItem("user", this.account);
              localStorage.setItem("pass", this.pwd);
            } else {
              localStorage.setItem("user", "");
              localStorage.setItem("pass", "");
            }
            this.$store.commit("setPersonal", res.data.data);
            sessionStorage.setItem("id", res.data.data.enterprise_uuid);
            sessionStorage.setItem("role_id", res.data.data.org_uuid);
            sessionStorage.setItem("token", res.data.data.accessToken);
            sessionStorage.setItem("permissions", JSON.stringify(res.data.data.permissions));
            sessionStorage.setItem("enterprise", res.data.data.enterprise_uuid);
            sessionStorage.setItem("loginMsg", JSON.stringify(res.data.data));
            if(res.data.data && res.data.data.user_code && (this.codeList.indexOf(res.data.data.user_code) > -1 || res.data.data.user_code == 'managertmh')) {
              this.$router.replace({ name: "orderList" });
            } else {
              this.$router.replace({ name: "Index" });
            }
          } else {
            sessionStorage.setItem("token", false);
            sessionStorage.setItem("enterprise", false);
            this.$confirm("用户名账号密码不正确", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(err => {
          console.log(err);
          this.$confirm("接口异常", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {})
            .catch(() => {});
        });
    }
  },
  created() {
    if (localStorage.getItem("pass")) {
      this.rtpwd = true;
      this.account = localStorage.getItem("user") || ""; //获取user的值并保存
      this.pwd = localStorage.getItem("pass") || ""; //获取password的值并保存
    }
  }
  // mounted() {
  //   this.$toTop(this);
  // }
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup"
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;

  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>
