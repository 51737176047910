import Vue from "vue";
import VueRouter from "vue-router";
// import Login from "@/views/login/Login.vue";
// import Password from "@/views/login/Password.vue";
import store from "@/store";
import {
  constantRouterMap
} from '@/router/routers'

Vue.use(VueRouter);

// export const constantRouterMap = [
//   // 根目录重定向
//   {
//     path: "/", // 根目录
//     redirect: "/login/password" //重定向
//     // component: Home
//   },
//   //登录页面
//   {
//     path: "/login",
//     name: "Login",
//     component: Login,
//     children: [
//       {
//         path: "password",
//         name: "Password",
//         component: Password
//       },
//       {
//         path: "code",
//         name: "Code",
//         component: () => import("../views/login/Code.vue") //懒加载
//       },
//       {
//         path: "forgetpwd",
//         name: "Forgetpwd",
//         component: () => import("../views/login/Forgetpwd.vue")
//       }
//     ]
//   },

//   //首页
//   {
//     path: "/index",
//     name: "Index",
//     component: () => import("@/views/BMap/BMap.vue")
//   },
//   //订单列表
//   {
//     path: "/order-list",
//     name: "orderList",
//     component: () => import("@/views/orderList.vue")
//   },
//   {
//     path: "/tables",
//     name: "Tables",
//     component: () => import("../views/tablebrowse/Tables.vue")
//   },
//   {
//     path: "/create",
//     name: "Create",
//     component: () => import("../views/Create.vue")
//   },
//   //个人中心
//   {
//     path: "/personal",
//     name: "Personal",
//     component: () => import("../views/personal/Personal.vue")
//   },
  
//   // 错误路由重定向
//   {
//     path: "*", // 错误路由
//     redirect: "/login/password" //重定向
//   }
// ];
// const routersList = [
//   //员工中心
//   {
//     path: "/staff",
//     name: "Staff",
//     meta: {
//       permissions: "100101"
//     },
//     component: () => import("@/views/staff/Staff.vue")
//   },
//   //角色管理
//   {
//     path: "/role",
//     name: "role",
//     meta: {
//       permissions: "100201"
//     },
//     component: () => import("@/views/role/role.vue")
//   },
//   //角色权限修改
//   {
//     path: "/role/jurisdiction",
//     name: "jurisdiction",
//     component: () => import("@/views/role/jurisdiction/jurisdiction.vue")
//   },
//   //组织管理
//   {
//     path: "/organization",
//     name: "organization",
//     meta: {
//       permissions: "100301"
//     },
//     component: () => import("../views/organization/organization.vue")
//   },
// ]
// let permissionsList = sessionStorage.getItem("permissions");
// if(permissionsList) {
//   routes = routes.filter(item => {
//     if(item.permissions) {
//       return permissionsList.some(permissionsId => {
//         return  item.permissions == permissionsId
//       })
//     } else {
//       return true
//     }
//   })

// }

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes: constantRouterMap
});
let flag = true;
router.beforeEach((to, from, next) => {
  console.log(to, "sdfsdjfsfk");
  // let token = localStorage.getItem("token");
  let permissionsList = sessionStorage.getItem("permissions") || null;
  if(typeof permissionsList == 'string') {
    permissionsList = permissionsList.split(',')
  }
  // console.log(router, "sdsdfjksfjsdk", sessionStorage.getItem("token"), store.state.authorisedRouters,sessionStorage.getItem("id"));
  if (sessionStorage.getItem("token")) {
    if (sessionStorage.getItem("id") || sessionStorage.getItem("role_id")) {
      if (!store.state.authorisedRouters || store.state.authorisedRouters.length == 0 || flag) {
        flag = false;
        store.dispatch('GenerateRoutes', permissionsList).then(() => {
          router.addRoutes(store.state.authorisedRouters);
          next({
            ...to,
            replace: true
          })
        })
      } else {
        if(to.path !== "/login/password" && to.meta && to.meta.permissions && sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").indexOf(to.meta.permissions) == -1) {
          next({
            path: from.path
          });
        } else {
          next();
        }
      }
    } else {
      next({ path: "/login/password" });
    }
  } else {
    if (to.path !== "/login/password") {
      next({ path: "/login/password" });
    } else {
      next();
    }
  }
});

export default router;
