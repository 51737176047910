import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import Login from "@/views/login/Login.vue";
// import Password from "@/views/login/Password.vue";
import store from "@/store";
import ElementUI from "element-ui";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import "./assets/reset.css";
import "./assets/iconfont/iconfont.css";
import './assets/font2/iconfont.css' // ruoyi css
import "swiper/swiper-bundle.css";
import Bolb from "./excel/Blob";
import Export2Excel from "./excel/Export2Excel";
import Swiper, { Navigation, Pagination } from "swiper"; // 这行代码很关键
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import axios from 'axios';
axios.defaults.baseURL='https://onecrmtest.gomoretech.com';
axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;
Vue.prototype.codeList = [
  "CN5112S140",
  "CN5112S141",
  "CN5112S143",
  "CN5112S144",
  "CN5112S145",
  "CN5112S146",
  "CN5112S147",
  "CN5112S149",
  "CN5112S150",
  "CN5112S153",
  "CN5112S155",
  "CN5112S156",
  "CN5112S157",
  "CN5112S159",
  "CN5112S160",
  "CN5112S138"
];
Vue.config.productionTip = false;
console.log(Bolb, Export2Excel, docxtemplater, PizZip, JSZipUtils, saveAs);

Swiper.use([Navigation, Pagination]); // 这行代码很关键

// 引入粒子特效
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
Vue.use(VueAwesomeSwiper) /* { default options with global component } */;
//全局公共方法
import Common from "./assets/api/Common";
Vue.prototype.$common = Common;

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
