import Vue from "vue";
import Vuex from "vuex";
import {
  asyncRouterMap,
  constantRouterMap
} from '@/router/routers';
import router from '@/router/index';
// import createPersistedstate from 'vuex-persistedstat';
Vue.use(Vuex);
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param permissions 权限列表
 * @param route
 */
function hasPermission(permissions, route) {
  if (!route.meta || !route.meta.permission) {
    return true
  } else {
    var rp = route.meta.permission;
    return permissions.some(perm => (perm === rp) || perm.indexOf(rp + ":") === 0);
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routers 路由表
 * @param permissions 权限列表
 */
function filterAsyncRouter(routers, permissions) {
  const filteredRouters = routers.filter(route => {
    if (hasPermission(permissions, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, permissions)
      }
      return true
    }
    return false
  })
  return filteredRouters
}

export default new Vuex.Store({
  state: {
    personal: {},
    routers: constantRouterMap,
    authorisedRouters: []
  },
  mutations: {
    setPersonal(state, data) {
      state.personal = data;
    },
    SET_ROUTERS: (state, routers) => {
      state.authorisedRouters = routers
      state.routers = constantRouterMap.concat(routers);
      console.log(state.routers," dfjksjf", state.authorisedRouters);
    }
  },
  actions: {
    GenerateRoutes({
      commit,
      state
    }, permissions) {
      // var permissions = user.permissions || [];
      return new Promise(resolve => {
        const accessedRouters = filterAsyncRouter(asyncRouterMap, permissions)
        commit('SET_ROUTERS', accessedRouters)
        resolve()
      })
    }
  },
  modules: {},
  // plugins: [createPersistedstate()]
});
